const $ = require('jquery');
import Chart from 'chart.js/auto';

export function renderFullChart() {
	let $stats = $('#stats-section-wrapper');
	const data = $stats.data('stats');

	return new Chart($('#stats-chart-full'), {
		type: 'bar',
		data: {
			datasets: [{
				data: data.full,
				label: 'Kontakty / Typológia (mesačný súhrn)',
				backgroundColor: [
					'rgba(16,0,82,0.2)',
					'rgba(0,63,92,0.2)',
					'rgba(47,75,124,0.2)',
					'rgba(102,81,145,0.2)',
					'rgba(160,81,149,0.2)',
					'rgba(212,80,135,0.2)',
					'rgba(249,93,106,0.2)',
					'rgba(255,124,67,0.2)',
					'rgba(255,166,0,0.2)',
					'rgba(208,163,0,0.2)',
					'rgba(165,157,14,0.2)',
					'rgba(127,147,36,0.2)',
					'rgba(94,134,52,0.2)',
					'rgba(67,120,63,0.2)',
					'rgba(48,105,69,0.2)',
					'rgba(38,89,70,0.2)',

				],
				borderColor: [
					'rgba(16,0,82)',
					'rgba(0,63,92)',
					'rgba(47,75,124)',
					'rgba(102,81,145)',
					'rgba(160,81,149)',
					'rgba(212,80,135)',
					'rgba(249,93,106)',
					'rgba(255,124,67)',
					'rgba(255,166,0)',
					'rgba(208,163,0)',
					'rgba(165,157,14)',
					'rgba(127,147,36)',
					'rgba(94,134,52)',
					'rgba(67,120,63)',
					'rgba(48,105,69)',
					'rgba(38,89,70)',
				],
				borderWidth: 1
			}]
		},
		options: {
			scales: {
				y: {
					beginAtZero: true
				}
			},
			parsing: {
				xAxisKey: 'name',
				yAxisKey: 'count'
			},
			plugins: {
				title: {
					display: true,
					align: 'end',
					text: 'Zobrazenie dát od začiatku aktuálneho mesiaca.'
				}
			}
		},
	});
}

export function renderShortChart() {
	let $stats = $('#stats-section-wrapper');
	const data = $stats.data('stats');

	return new Chart($('#stats-chart-short'), {
		type: 'bar',
		data: {
			datasets: [{
				data: data.short,
				label: 'Krátke kontakty / Typológia (mesačný súhrn)',
				backgroundColor: [
					'rgba(16,0,82,0.2)',
					'rgba(0,63,92,0.2)',
					'rgba(47,75,124,0.2)',
					'rgba(102,81,145,0.2)',
					'rgba(160,81,149,0.2)',
					'rgba(212,80,135,0.2)',
					'rgba(249,93,106,0.2)',
					'rgba(255,124,67,0.2)',
					'rgba(255,166,0,0.2)',
					'rgba(208,163,0,0.2)',
					'rgba(165,157,14,0.2)',
					'rgba(127,147,36,0.2)',
					'rgba(94,134,52,0.2)',
					'rgba(67,120,63,0.2)',
					'rgba(48,105,69,0.2)',
					'rgba(38,89,70,0.2)',

				],
				borderColor: [
					'rgba(16,0,82)',
					'rgba(0,63,92)',
					'rgba(47,75,124)',
					'rgba(102,81,145)',
					'rgba(160,81,149)',
					'rgba(212,80,135)',
					'rgba(249,93,106)',
					'rgba(255,124,67)',
					'rgba(255,166,0)',
					'rgba(208,163,0)',
					'rgba(165,157,14)',
					'rgba(127,147,36)',
					'rgba(94,134,52)',
					'rgba(67,120,63)',
					'rgba(48,105,69)',
					'rgba(38,89,70)',
				],
				borderWidth: 1
			}]
		},
		options: {
			scales: {
				y: {
					beginAtZero: true
				}
			},
			parsing: {
				xAxisKey: 'name',
				yAxisKey: 'count'
			},
			plugins: {
				tooltip: {
					callbacks: {
						afterTitle: function(context) {
							return context[0].raw.tooltip;
						}
					}
				},
				title: {
					display: true,
					align: 'end',
					text: 'Zobrazenie dát od začiatku aktuálneho mesiaca.'
				}
			},
		},
	});
}

export function renderUserChart() {
	let $stats = $('#stats-section-wrapper');
	const data = $stats.data('stats');

	return new Chart($('#stats-chart-user'), {
		type: 'bar',
		data: {
			datasets: [{
				data: [...data.user.full, ...data.user.short],
				label: 'Osobné štatistiky',
				backgroundColor: [
					'rgba(16,0,82,0.2)',
					'rgba(0,63,92,0.2)',
					'rgba(47,75,124,0.2)',
					'rgba(102,81,145,0.2)',
					'rgba(160,81,149,0.2)',
					'rgba(212,80,135,0.2)',
					'rgba(249,93,106,0.2)',
					'rgba(255,124,67,0.2)',
					'rgba(255,166,0,0.2)',
					'rgba(208,163,0,0.2)',
					'rgba(165,157,14,0.2)',
					'rgba(127,147,36,0.2)',
					'rgba(94,134,52,0.2)',
					'rgba(67,120,63,0.2)',
					'rgba(48,105,69,0.2)',
					'rgba(38,89,70,0.2)',
				],
				borderColor: [
					'rgba(16,0,82)',
					'rgba(0,63,92)',
					'rgba(47,75,124)',
					'rgba(102,81,145)',
					'rgba(160,81,149)',
					'rgba(212,80,135)',
					'rgba(249,93,106)',
					'rgba(255,124,67)',
					'rgba(255,166,0)',
					'rgba(208,163,0)',
					'rgba(165,157,14)',
					'rgba(127,147,36)',
					'rgba(94,134,52)',
					'rgba(67,120,63)',
					'rgba(48,105,69)',
					'rgba(38,89,70)',
				],
				borderWidth: 1
			}]
		},
		options: {
			scales: {
				y: {
					beginAtZero: true
				}
			},
			parsing: {
				xAxisKey: 'name',
				yAxisKey: 'count'
			},
			plugins: {
				tooltip: {
					callbacks: {
						afterTitle: function(context) {
							return context[0].raw.tooltip;
						}
					}
				},
				title: {
					display: true,
					align: 'end',
					text: 'Zobrazenie dát od začiatku aktuálneho mesiaca.'
				}
			}
		},
	});
}
