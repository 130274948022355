// import '@popperjs/core/dist/esm/index';
import 'bootstrap/dist/js/bootstrap.bundle';
import '@fortawesome/fontawesome-free/js/brands';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/fontawesome';
import 'flatpickr';
import * as moment from 'moment';
import './js/init-datepicker';
import {renderFullChart, renderShortChart, renderUserChart} from './js/init-dashboard-charts';


// require jQuery normally
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;
global.moment = moment;
global.renderFullChart = renderFullChart;
global.renderShortChart = renderShortChart;
global.renderUserChart = renderUserChart;
