import { Slovak } from 'flatpickr/dist/l10n/sk';

$(document).ready(function () {
	$('.date-picker').flatpickr({
		locale: Slovak,
		dateFormat: 'd.m.Y',
	});
	$('.date-picker').removeAttr('readonly');

	$('.date-time-picker').flatpickr({
		locale: Slovak,
		enableTime: true,
		dateFormat: 'd.m.Y H:i',
	});

	$('.date-time-picker').removeAttr('readonly');
});
